<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/*-----------+google fonts+------------*/

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css");

/*-----------+google fonts+------------*/

/*-----------+common css start+------------*/
* {
  margin: 0;
  padding: 0;
  outline: 0;
}

a,
a:hover {
  text-decoration: none;
  display: inline-block;
}

ul,
ol,
li {
  list-style-type: none;
}

.title {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #000;
}

.title_product {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #000 !important;
  font-size: 10px;
}

.bold_big {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 800;
}

body {
  overflow-x: hidden;
  user-select: none;
  font-family: "Open Sans", sans-serif;
  background-color: #faf9f9;
  font-size: 8;
  font-weight: 400;
}

/*-----------+common css end+------------*/

/*-----------+topbar part start+------------*/

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fafafad0;
  padding: 10px 20px;
  height: 40px;
}

.topbar_left a i {
  padding: 0px 15px;
  color: #00a598;
  border-right: 1px solid #eee;
}

#last_child {
  border: none;
}

.topbar_right ul {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.topbar_right ul li {
  margin-top: 5px;
}

.topbar_right ul li a {
  margin: 0px 10px;
  color: #000;
  font-weight: 300;
  font-size: 14px;
  border-right: 2px solid #eee;
  padding-right: 15px;
}

#dropDown,
#signUp_drop {
  background-color: #fbfbfb;
  border: none;
  font-weight: 300;
  font-size: 16px;
}

#sign_up,
#signUp_drop {
  margin-top: 5px;
  background-color: #00a598;
  padding: 3px 5px;
  border-radius: 8px;
  color: #fff;
}

/*-----------+topbar part start+------------*/

/*-----------+search bar part start+------------*/
#search_bar {
  padding: 25px 0px;
  background-color: #ffffff;
}

#search_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#inputField {
  border: 2px solid #37a537;
  outline: none;
  border-radius: 8px;
}

#searchBtn {
  background-color: #37a537;
  color: #fff;
  text-transform: capitalize;
  font-weight: 300;
  font-family: inherit;
  font-size: 18px;
}

.search_icon {
  margin-left: 30px;
}

.search_icon i {
  color: #ff4747;
  margin: 15px 10px;
}

#navbar {
  background-color: #ffffff;
}

.nav-item .nav-link {
  color: #000;
  font-weight: 300;
  font-size: 17px;
  transition: all linear 0.4s;
}

.nav-item .nav-link:hover {
  color: #ff4747;
}

/*-----------+search bar part start+------------*/

/*-----------+requirements part start+------------*/
#requirements {
  padding: 30px 0px;
  background-color: rgba(218, 213, 213, 0.138);
}

.requirements_form {
  background-color: #fff;
  padding: 20px;
  box-shadow: 2px 0px 2px 2px #b4b2b2;
}

.requirements_form h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #000;
  font-size: 24px;
  text-transform: capitalize;
}

.requirements_form input,
textarea {
  padding: 5px 10px;
  border-radius: 8px;
  border: 2px solid #eee;
  width: 100%;
  margin: 10px 0px;
  font-weight: 700;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
}

.requirements_form button {
  background-color: #40a541;
  width: 100%;
  padding: 10px 0px;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-weight: 700;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
}

.requirements_btn {
  margin: 8px 0px;
  padding: 8px 0px;
  background-image: url("./assets/image/tt.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.requirements_btn button {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 15px;
  padding: 5px 0px;
  border: 1px solid #ffffff;
  margin: 0px 27px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 700;
  font-family: "Nunito Sans", sans-serif;
  font-size: 10px;
  box-shadow: 1px 0px 1px 1px #302f2f;
}

.requirements_img img {
  border-radius: 8px;
  max-height: 100%;
}

.requirements_img {
  position: relative;
  height: 385px;
}

.requirements_img:after {
  content: "";
  background-color: rgba(0, 0, 0, 0.125);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.requirements_img_text {
  z-index: 999;
  position: absolute;
  color: #fff;
  top: 20%;
  left: 5%;
}

.requirements_img_text button {
  background-color: rgb(216, 97, 41);
  padding: 5px 30px;
  border-radius: 15px;
  border: none;
  color: #fff;
  text-transform: capitalize;
  font-weight: 700;
  font-family: "Nunito Sans", sans-serif;
  font-size: 15px;
}

/*-----------+requirements part end+------------*/

/*-----------+deal part start+------------*/
#deals {
  padding: 30px 0px;
  background-color: rgba(218, 213, 213, 0.138);
}

.deals_img {
  background-color: #fff;
  box-shadow: 1px 0px 1px 1px #bbbaba;
}

.best_deals {
  border: 2px solid #37a537;
  border-radius: 15px;
}

.heading_count h3 {
  padding: 3px 5px;
  color: #fff;
  background-color: #ff4747;
  border-radius: 3px;
}

.heading_count p {
  font-size: 18px;
  font-weight: 700;
  vertical-align: middle;
}

.heading_count {
  display: flex;
  align-items: center;
  margin: 0px 10px;
}

.heading_left {
  display: flex;
  margin: 20px 0px;
}

.bootm-offer > img {
  width: 100%;
  border-radius: 10px;
}

.brand_image > img {
  border-radius: 10px;
  box-shadow: 1px 0px 1px 1px #000;
}

.join {
  padding: 50px 0px;
  background-color: #0b8a7e;
}

.home-newsletter {
  padding: 20px 0;
}

.home-newsletter .single {
  max-width: 350px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 2;
}

.home-newsletter .single p {
  font-size: 8px;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  margin-bottom: 40px;
}

.home-newsletter .single .form-control {
  height: 50px;
  background: rgba(255, 255, 255, 0.6);

  border: 1px solid #dc3545;
  border-radius: 10px 0 0 10px;
}

.home-newsletter .single .form-control:focus {
  box-shadow: none;
  border-color: #dc3545;
}

.home-newsletter .single .btn {
  min-height: 50px;
  border-radius: 0 10px 10px 0;
  background: #dc3545;
  color: #fff;
}

.fa {
  color: #37a537;
}

/*-----------+deal part end+------------*/

/* sub menu category */
.product-dropdown-menu {
  display: flex;

  position: absolute;
  background: white;
  z-index: 9999;
  box-shadow: 0px 0px 5px -1px #dbdbdb;
  top: 42px;
  transition: 0.3s;
  transform: scaleY(0);
  transform-origin: top;
}

.product-dropdown-menu ul {
  width: 230px;
  padding-left: 0px;
  border-right: 1px solid #ddd;
}

.product-dropdown-menu ul h4 {
  padding: 9px 23px;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 0px;
}

.product-dropdown-menu ul li {
  font-size: 14px;
  padding: 5px 20px;
}

.product-dropdown-menu ul:nth-child(3) {
  border: none;
}

ul.navbar-nav .nav-item:hover .product-dropdown-menu {
  transform: scaleY(1);
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  width: 690px;
}

.mega-menu-child-menu {
  display: none;
}

li.mega-menu-wrapper .mega-menu-items-wrapper {
  height: 100vh;
  width: 240px;
  position: absolute;
  z-index: 9999;
  background: white;
  transform-origin: top;
  transform: scaleY(0);
  transition: 0.4s;
}

li.mega-menu-wrapper .mega-menu-items-wrapper li {
  padding: 10px 0px;
}

li.mega-menu-wrapper .mega-menu-items-wrapper .mega-menu-list-wrapper {
  padding-left: 0px;
}

li.mega-menu-wrapper .mega-menu-items-wrapper .mega-menu-list-wrapper li {
  padding: 0px;
}

li.mega-menu-wrapper .mega-menu-items-wrapper .mega-menu-list-wrapper li:hover {
  background: aliceblue;
}

li.mega-menu-wrapper .mega-menu-items-wrapper .mega-menu-list-wrapper li a {
  display: block;
  padding: 10px 20px;
  transition: 0.3s;
  color: #343030;
  font-size: 15px;
  font-weight: 500;
  font-family: sans-serif;
}

li.mega-menu-wrapper
  .mega-menu-items-wrapper
  .mega-menu-list-wrapper
  li:hover
  a {
  padding-left: 25px;
  font-weight: bold;
}

.mega-menu-child-menu .mega-menu-child-list {
  width: 250px;
  padding: 0px;
}

.mega-menu-child-menu .mega-menu-child-list li a {
  /* padding: 10px 0px; */
}

.mega-menu-wrapper
  .mega-menu-items-wrapper
  .mega-menu-list-wrapper
  li:hover
  .mega-menu-child-menu {
  transform: scaleX(1);
}

.mega-menu-wrapper
  .mega-menu-items-wrapper
  .mega-menu-list-wrapper
  .mega-menu-child-menu {
  display: flex;
  position: absolute;
  left: 240px;
  background: #f8f9faf2;
  top: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.5s;
}

.mega-menu-wrapper
  .mega-menu-items-wrapper
  .mega-menu-list-wrapper
  .mega-menu-child-menu
  .mega-menu-child-list
  li {
  padding: 0px 0;
}

.mega-menu-wrapper
  .mega-menu-items-wrapper
  .mega-menu-list-wrapper
  .mega-menu-child-menu
  .mega-menu-child-list
  li:hover {
  color: red;
}

.mega-menu-wrapper
  .mega-menu-items-wrapper
  .mega-menu-list-wrapper
  .mega-menu-child-menu
  .mega-menu-child-list
  li
  a {
  padding: 6px 15px !important;
  width: 100%;
  font-size: 12px;
  transition: 0.5s;
}

.mega-menu-wrapper
  .mega-menu-items-wrapper
  .mega-menu-list-wrapper
  .mega-menu-child-menu
  .mega-menu-child-list
  li
  a:hover {
  color: #e50019;
  font-weight: bold;
}

.mega-menu-wrapper
  .mega-menu-items-wrapper
  .mega-menu-list-wrapper
  .mega-menu-child-menu
  .mega-menu-child-list
  h5 {
  padding: 16px 16px;
  margin-bottom: 0px;
  border-bottom: 1px solid #ddd;
  font-size: 13px;
  font-weight: 900;
}

.mega-menu-wrapper
  .mega-menu-items-wrapper
  .mega-menu-list-wrapper
  .mega-menu-child-menu
  .mega-menu-child-list
  li
  a:hover {
  color: #ff4747;
  padding-left: 20px !important;
}

li.nav-item.mega-menu-wrapper:hover .mega-menu-items-wrapper {
  transform: scaleY(1);
}

.requirements_form textarea {
  height: 79px;
}

button.btn.btn-outline-secondary.dropdown-toggle {
  background: #37a537;
  transition: 0.5s;
  color: white;
}

button#searchBtn i {
  color: white;
}

button.show-reg-form.modal-open {
  background: #37a537;
  border: none;
  padding: 5px 18px;
}

button#searchBtn:focus {
  box-shadow: none;
}

button.btn.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.navbar-toggler:focus {
  box-shadow: 1px 1px 1px 1px #ddd;
}

/* All responsive code is here */

@media only screen and (max-width: 770px) {
  .topbar {
    flex-direction: column;
    height: fit-content;
    margin: 0;
  }

  .container {
    max-width: 96% !important;
  }

  .topbar.container {
    width: 100%;
    max-width: 100%;
  }

  .topbar .topbar_right ul li a {
    font-size: 12px !important;
    padding-left: 12px;
    width: 100%;
    margin: 0px 0px;
  }

  .topbar .topbar_right ul li a select {
    font-size: 12px !important;
  }

  .topbar select#signUp_drop {
    margin-top: 0px;
  }

  .requirements_img {
    margin-bottom: 20px;
  }

  .search_area.col-md-8 {
    padding-left: 25px;
  }

  button.navbar-toggler {
    position: relative;
    bottom: 16px;
  }

  .mega-menu-items-wrapper {
    height: fit-content;
  }

  li.mega-menu-wrapper .mega-menu-items-wrapper {
    height: fit-content;
    width: 100%;
  }

  .mega-menu-items-wrapper {
  }

  .mega-menu-wrapper
    .mega-menu-items-wrapper
    .mega-menu-list-wrapper
    .mega-menu-child-menu {
    display: block;
    width: 100%;
    left: 0; /* top: 42px; */
  }

  li.nav-item.mega-menu-wrapper:hover .mega-menu-items-wrapper,
  .mega-menu-wrapper
    .mega-menu-items-wrapper
    .mega-menu-list-wrapper
    li:hover
    .mega-menu-child-menu {
    position: relative;
  }

  li.nav-item.mega-menu-wrapper:hover .mega-menu-items-wrapper {
    height: fit-content;
    width: 100%;
  }

  .mega-menu-child-menu .mega-menu-child-list {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
  }

  li.mega-menu-wrapper .mega-menu-items-wrapper .mega-menu-list-wrapper {
    width: 100%;
  }

  .product-dropdown-menu ul {
    flex-grow: 1;
  }

  ul.navbar-nav .nav-item:hover .product-dropdown-menu {
    position: relative;
    top: 0;
  }

  section#search_bar {
    padding: 0;
  }
}

@media only screen and (max-width: 992px) {
  #inputField {
    margin: 0 15px;
  }

  li.nav-item.mega-menu-wrapper:hover .mega-menu-items-wrapper {
    position: relative;
    height: fit-content;
    width: 100%;
  }

  .mega-menu-child-menu {
    display: flex;
    flex-wrap: wrap;
  }

  .mega-menu-child-menu .mega-menu-child-list {
    width: 228px;
    padding: 0px;
    flex-grow: 1;
  }

  .mega-menu-child-menu .mega-menu-child-list {
    flex-grow: 1;
  }

  .mega-menu-wrapper
    .mega-menu-items-wrapper
    .mega-menu-list-wrapper
    .mega-menu-child-menu {
    display: flex;
    flex-grow: 1;
    width: 100%;
  }

  .product-grid .product-sale-label {
    line-height: 45px;
    top: 15px;
    left: 15px;
  }

  .deals_heading.row #view_all {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .nav-item .nav-link {
    font-size: 12px;
  }
}

@media only screen and (max-width: 425px) {
  .nav-item .nav-link {
    padding: 6px;
  }

  .visit-out-site-btn-wrapper a:first-child {
    margin-bottom: 0px !important;
  }
}

@media only screen and (max-width: 375px) {
  .nav-item .nav-link {
    font-size: 11px;
  }
}

@media only screen and (max-width: 325px) {
  .nav-item .nav-link {
    font-size: 10px;
    padding: 4px;
  }
}

@media only screen and (max-width: 960px) {
  .lg-mr-b {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 600px) {
  button.btn.btn-outline-secondary.dropdown-toggle {
    font-size: 12px;
    padding: 6px 8px;
  }

  div#inputField .form-control {
    padding: 6px 6px;
    font-size: 12px;
  }

  #searchBtn {
    font-size: 12px;
  }
}

@media only screen and (max-width: 520px) {
  .topbar .topbar_right ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 0;
  }

  section#search_bar {
    padding-bottom: 70px;
  }

  #inputField {
    margin: 0px;
  }

  .topbar .topbar_right ul li {
    width: 50%;
  }

  .topbar .topbar_right ul li a {
    padding-right: 0px;
  }

  .topbar .topbar_right ul li:nth-child(2) a {
    border-right: none !important;
  }

  .search_area.col-md-8 {
    position: absolute;
    top: 45px;
    z-index: 11;
    padding: 11px;
    width: 95%;
  }

  div#search_flex {
    position: relative;
    align-items: center;
  }

  .search_area.col-md-8 #inputField button:nth-child(1) {
    font-size: 12px;
    padding: 8px;
    font-weight: 600;
  }

  .search_area.col-md-8 #inputField button#searchBtn {
    font-size: 11px;
    padding: 8px;
    font-weight: 600;
  }

  .topbar_right ul li:last-child button {
    padding: 4px 20px;
    font-size: 14px;
  }

  section#deals {
    padding: 0px;
  }

  .best_deals {
    border: none;
  }

  a#view_all {
    margin-bottom: 20px;
    padding: 5px 20px;
    background: #40a541;
    color: white;
    border-radius: 7px;
  }

  .product-grid .product-sale-label {
    line-height: 45px;
    top: 15px;
    left: 15px;
  }

  .col-md-4.bootm-offer {
    margin-bottom: 15px;
  }

  .col-md-4.bootm-offer:last-child {
    margin-bottom: 0px;
  }

  .deals_heading .heading_ledt {
    padding: 27px;
  }

  .deals_heading .heading_left {
    margin: 0px;
  }

  .deals_heading.row .col-md-1.mt-4 {
    margin-top: 8px !important;
  }

  section.promotion .bootm-offer {
    margin-bottom: 15px;
  }

  div#search_flex .logo {
    padding-left: 10px;
  }

  div#search_flex .search_icon {
    padding-right: 10px;
  }

  .requirements_img_text {
    top: 15%;
  }

  .requirements_img {
    height: fit-content;
  }
}

@media only screen and (max-width: 375px) {
  .requirements_img_text {
    top: 8%;
  }

  .requirements_img {
    height: fit-content;
  }

  .requirements_img_text button {
    font-size: 13px;
  }

  .requirements_img_text h2 {
    font-size: 16px;
  }

  .requirements_img_text p {
    font-size: 12px;
  }

  .requirements_img_text h1 {
    font-size: 18px;
  }

  .requirements_img_text {
    top: 15%;
  }

  .requirements_img {
    height: fit-content;
  }
}

@media only screen and (max-width: 990px) {
  nav#navbar #navbarNavDarkDropdown.active .menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #dfdfdf91;
  }

  nav#navbar #navbarNavDarkDropdown {
    position: fixed;
    height: 100vh;
    width: 230px;
    background: white;
    z-index: 1111;
    top: 0;
    left: -500px;
    overflow-y: auto;
    box-shadow: 1px 1px 1px #e7e7e763;
    transition: 0.5s;
  }

  nav#navbar #navbarNavDarkDropdown.active {
    left: 0px;
  }

  nav#navbar #navbarNavDarkDropdown .navbar-nav {
    background: white;
    position: fixed;
    height: 100%;
    width: 220px;
    padding: 0px 15px;
    overflow-y: auto;
  }

  .mega-menu-wrapper
    .mega-menu-items-wrapper
    .mega-menu-list-wrapper
    li:hover
    .mega-menu-child-menu {
    position: relative;
    display: block;
    left: 0;
    top: 0px;
  }

  ul.navbar-nav .nav-item:hover .product-dropdown-menu {
    position: relative;
    top: 0px;
  }

  ul.navbar-nav .nav-item:hover .product-dropdown-menu {
    width: 100% !important;
  }

  .product-grid {
    font-family: "Roboto", sans-serif;
    text-align: center;
    transition: all 0.5s;
  }
  .product-grid:hover {
    box-shadow: 0 5px 18px rgba(0, 0, 0, 0.3);
  }
  .product-grid .product-image {
    position: relative;
    overflow: hidden;
  }
  .product-grid .product-image a.image {
    display: block;
  }
  .product-grid .product-image img {
    width: 100%;
    height: auto;
  }
  .product-image .pic-1 {
    opacity: 1;
    backface-visibility: hidden;
    transition: all 0.5s;
  }
  .product-grid:hover .product-image .pic-1 {
    opacity: 0;
  }
  .product-image .pic-2 {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.5s;
  }
  .product-grid:hover .product-image .pic-2 {
    opacity: 1;
  }
  .product-grid .product-sale-label {
    color: #fff;
    background: #40a541;
    font-size: 12px;
    font-style: italic;
    text-transform: uppercase;
    width: 45px;
    height: 45px;
    line-height: 55px;
    border-radius: 50px;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .product-grid .social {
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    top: 15px;
    right: 7px;
  }
  .product-grid .social li {
    transform: translateX(60px);
    transition: all 0.3s ease 0.3s;
  }
  .product-grid .social li:nth-child(2) {
    transition: all 0.3s ease 0.4s;
  }
  .product-grid:hover .social li {
    transform: translateX(0);
  }
  .product-grid .social li a {
    color: #707070;
    background: #fff;
    font-size: 12px;
    line-height: 40px;
    width: 40px;
    height: 40px;
    margin: 0 0 7px;
    border-radius: 50px;
    display: block;
    transition: all 0.3s ease 0s;
  }
  .product-grid .social li a:hover {
    color: #6da84a;
  }
  .product-grid .product-rating {
    background: rgba(255, 255, 255, 0.95);
    width: 100%;
    padding: 10px;
    opacity: 0;
    position: absolute;
    bottom: -60px;
    left: 0;
    transition: all 0.2s ease-in-out 0s;
  }
  .product-grid:hover .product-rating {
    opacity: 1;
    bottom: 0;
  }
  .product-grid .rating {
    padding: 0;
    margin: 0;
    list-style: none;
    float: left;
  }
  .product-grid .rating li {
    color: #181a17;
    font-size: 12px;
  }
  .product-grid .rating li.far {
    color: #999;
  }
  .product-grid .add-to-cart {
    color: #6da84a;
    font-size: 12px;
    font-weight: 600;
    border-bottom: 1px solid #6da84a;
    float: right;
    transition: all 0.2s ease-in-out 0s;
  }
  .product-grid .add-to-cart:hover {
    color: #000;
    border-color: #000;
  }
  .product-grid .product-content {
    background: #ffffff;
    padding: 15px;
    margin-bottom: 10px;
  }
  .product-grid .title {
    font-size: 14px;
    text-transform: capitalize;
    margin: 0 0 5px;
  }
  .product-grid .title a {
    color: #111;
    transition: all 500ms;
  }
  .product-grid .title a:hover {
    color: #6da84a;
  }
  .product-grid .price {
    color: #707070;
    font-size: 14px;
    text-decoration: underline;
  }
  .product-grid .price span {
    text-decoration: line-through;
    margin-right: 5px;
  }
  #view_all {
    border-bottom: 2px solid #40a541;
  }
  .progress {
    height: 5px;
  }
  .progress-bar {
    height: 5px;
  }
  .product-sale-label {
    margin-right: 50px !important;
  }
  .product-content > span {
    font-size: 8px !important;
  }
}
</style>
